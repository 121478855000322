.schedule-navbar {
    background: $main_color;
    z-index: 1;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    height: 4rem;
    padding: .5rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;

    .picker-date {
        width: 1px;
        height: 1px;
        opacity: 0;
    }

    .content {
        cursor: pointer;
        @media screen and (max-width: 575px) {
            flex: 1 1;
        }
        color: white;
        margin: 0 1.5rem;

        .large, .small {
            margin-bottom: 0;
            text-align: center;
        }

        .large {
            text-transform: uppercase;
            font-size: 1.125rem;
            font-weight: 700;
            @media(max-width: 390px) {
                font-size: 1rem;
            }
        }

        .small {
            font-size: .75rem;
            font-weight: 700;
        }
    }

    .mat-icon-button {
        i {
            color: white;
            font-size: 1.5rem;
        }
    }

    .mat-button-disabled {
        opacity: 0.4;
    }
}
