$main_color: #0f0064;
$week_indicator_border: $main_color;
$sec_color: #85D1E4;
$gray_color: #F3F2F7;
$gray_color_mid: #dedce9;
$gray_color_dark: #c5c1d9;
$gray_color_darker: #767676;
$night_color: #b2b2b2;
$blue_stats_color: #c2bfd8;
$unavailable_color: #e6e6e6;
$unavailable_color_dark: #8F8F8F;
$blue_color: #67c6dd;
$green_color: #47c65c;
$green_sideborder_color: #379a47;
$green_darker_color: #379a47;
$green_darker_sidebar_color: #297635;
$red_color: #FF0000;
$red_sidebar_color: #b10000;
$border_color: #b8b4d2;
$border_blue_color: #2e2373;
$highlight: #f6f5f9;

$error_color: #f44336;

$blue: $main_color !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$warning_bg: #fff7e6;


$zindex_header: 1000;
$zindex_sticky: 10;
$zindex_unavailable: 8;
$zindex_planning: 9;
$zindex_planning_hover: 10;
$zindex_selector: 7;
$zindex_logo_login: 0;

$header_height: 60px;
$tons_height: 60px;
$hourWith: 10px;
$entityHeight: 95px;
$entityHeightBig: 104px;
$dateBarHeight: 30px;

$shadow-button: 0 2px 5px rgba(0, 0, 0, 0.2);
$shadow-normal: 0 5px 10px rgba(0, 0, 0, 0.2);
$shadow-big: 0 5px 30px rgba(0, 0, 0, 0.3);

$header-logo: url("/assets/images/logo-small.png");
$icon-append: "";

@mixin sideborder($color) {
    position: relative;
    &:before {
        content: ' ';
        width: 7px;
        background-color: $color;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }
}

@mixin warningBefore() {
    &:before {
        font-family: 'Font Awesome 6 Pro';
        font-weight: 900;
        content: '\f071';
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        padding-right: 10px;
    }
}

